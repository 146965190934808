import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Images from "../../Images";
import Navlist from "./navList";
import { getToken } from '../../authetication/loginState';
import "./Navbar.css";
import { useMediaQuery } from '@mui/material';

function Navbar({ loginState }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [controller, setController] = useState(false);
  const [open, setOpen] = useState(false);
  const [mobileState, setMobileState] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    setController(false);
  }, [location]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setMobileState(window.innerWidth <= 850);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isLoggedIn = getToken();

  const getNavbarClass = () => {
    const { pathname } = location;
    
    switch (true) {
      case !isMobile && pathname === '/' && !isLoggedIn:
        return 'newNavbar';
        
      case pathname.includes('/mockInterviewer'):
      case pathname.includes('/blog'):
      case pathname === '/':
      case pathname.includes('blog-details'):
        return 'navbar home';
        
      default:
        return 'navbar other';
    }
  };

  return (
    <nav className={getNavbarClass()}>
      <div className="navbar__logo">
        <img
          src={(location.pathname === '/' || location.pathname === '/mockInterviewer' || location.pathname === '/mockInterviewerInput' || location.pathname === '/mockInterviewerZoom' || location.pathname === '/mockInterviewerFeedback' || location.pathname === '/blog' || location.pathname.includes('blog-details')) ? Images.IG_LOGO2 : Images.IG_LOGO}
          alt="Intern guys"
          onClick={() => navigate("/")}
          className={open ? "noSmall" : ""}
        />
      </div>

      <div
        className="navbar__navController"
        onClick={() => setOpen(!open)}
      >
        <div className="navbar__bar" />
        <div className="navbar__bar" />
        <div className="navbar__bar" />
      </div>

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        anchor={isLoggedIn ? 'right' : 'top'}
        className={`navbar__nav ${open ? "open" : "navbar_noDisplay"}`}
      >
        <Navlist loginState={loginState} open={open} setOpen={setOpen} mobileState={mobileState} />
      </Drawer>
      <div className={`navbar__nav ${open ? "noSmall" : "navbar__noDisplay"}`}>
        <Navlist loginState={loginState} open={open} setOpen={setOpen} mobileState={mobileState} />
      </div>
    </nav>
  );
}

export default Navbar;